import React from 'react'
import styled from 'styled-components'
import { ButtonLight } from '../../components/Button'
//import { getContract, getSigner } from '../../utils'
import AppBody from '../AppBody'
import { useActiveWeb3React } from '../../hooks'
import { darken } from 'polished'
import { ChainId } from 'zdfnswap-sdk'
import { useWalletModalToggle } from '../../state/application/hooks'
import { DEFAULT_TOKEN_LIST_URL } from '../../constants/lists'

//import {useWalletModalToggle } from '../../state/application/hooks'

const FaucetTitle = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  justify-content: center;
  border-radius: 3rem;
  outline: none;
  text-decoration: none;
  color: ${({ theme }) => theme.text1};
  font-size: 20px;
  padding: 10px 20px;
`
const FaucetTips = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  justify-content: center;
  border-radius: 3rem;
  outline: none;
  text-decoration: none;
  color: ${({ theme }) => theme.text1};
  font-size: 16px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  line-height: 24px;
`

const DocuA = styled.a`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  justify-content: center;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.primaryText1};
  font-size: 14px;
  text-decoration: underline;
  font-weight: 500;
  margin: 16px 0;

  :hover {
    color: ${({ theme }) => darken(0.1, theme.primaryText1)};
    font-weight: 600;
  }
`
//const FaucetContractAddress = "0x3b11769B5332Efb999606c06563fC371dE6eF90A"
//const ZDATokenractAddress = "0x457F5Bacd72a096B78CAA6E4cC27c1b5175746c3"

export function Faucet() {
  //const { t } = useTranslation()
  // toggle wallet when disconnected
  //const toggleWalletModal = useWalletModalToggle()
  const { account, library, connector } = useActiveWeb3React()

  const toggleWalletModal = useWalletModalToggle()

  const requestTokens = async () => {
    if (!library || !connector) {
      return
    }
    if (!account) {
      //没有连接钱包或者是非scroll网络
      var scrollchainId = '0x' + ChainId.TESTNET.toString(16)
      try {
        await window.ethereum?.request({
          method: 'eth_requestAccounts',
          params: []
        })
        await window.ethereum?.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: scrollchainId }]
        })
      } catch (switchError) {
        if (switchError?.code === 4902) {
          try {
            const data = [
              {
                chainId: scrollchainId,
                chainName: 'Scroll Sepolia Testnet',
                nativeCurrency: {
                  name: 'ETH',
                  symbol: 'ETH',
                  decimals: 18
                },
                rpcUrls: [process.env.REACT_APP_NETWORK_URL],
                blockExplorerUrls: ['https://sepolia.scrollscan.com/']
              }
            ]
            await (window.ethereum as any)?.request({
              method: 'wallet_addEthereumChain',
              params: data
            })
          } catch (error) {
            console.error('wallet_addEthereumChain', error)
          }
        }
      }
      return
    }

    const text = `Just%20claimed%20my%20faucet%20tokens%20to%20test%20@Hippo_Swap's%20Testnet,%20the%20first%20native%20DEX%20Aggregator%20on%20Scroll!%20%F0%9F%9A%80%20Excited%20to%20explore%20and%20win%20big%20rewards.%0A%0AJoin%20me%20on%20this%20journey:%20${account}%0A%0A%23testnet%20%23Hipposwap%20%23scroll`
    const twitterText = `https://twitter.com/intent/tweet?text=${text}`
    window.open(twitterText, '_blank')
  }

  return (
    <AppBody>
      <FaucetTitle>Retweet to get our test tokens</FaucetTitle>
      <FaucetTitle>3 HIPPO + 1 USDT + 1 USDC</FaucetTitle>
      <FaucetTips>
        The tokens you receive from Hippo Swap's faucet are not real funds. You can request tokens every 24 hours. Just
        click the 'Retweet' button below, then share the tweet publicly to claim your tokens within 10 minutes.
      </FaucetTips>
      <DocuA
        href={'https://tokenlists.org/token-list?url=' + DEFAULT_TOKEN_LIST_URL}
        target="_blank"
        rel="noopener noreferrer"
      >
        Our tokens list
      </DocuA>
      <DocuA
        href="https://sepolia.scrollscan.com/address/0xd135FE519Bda20FFa65c3Ed762d6478312075966"
        target="_blank"
        rel="noopener noreferrer"
      >
        Hippo Swap Contract Address
      </DocuA>
      <DocuA
        href="https://docs.scroll.io/en/user-guide/faucet/#scroll-sepolia-faucets"
        target="_blank"
        rel="noopener noreferrer"
      >
        Scroll Sepolia Faucets
      </DocuA>
      {!account ? (
        <ButtonLight onClick={toggleWalletModal}>Connect Wallet</ButtonLight>
      ) : (
        <ButtonLight
          onClick={() => {
            requestTokens()
          }}
        >
          Retweet and Earn
        </ButtonLight>
      )}
    </AppBody>
  )
}
