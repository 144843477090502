import React from 'react'
import styled from 'styled-components'

export const BodyWrapper = styled.div`
  position: relative;
  max-width: 480px;
  width: 100%;
  background: ${({ theme }) => theme.bg1};
  border-radius: 20px;
  padding: 24px;
  border: 1px solid rgba(101, 42, 0, 0.71);
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  return <BodyWrapper>{children}</BodyWrapper>
}
