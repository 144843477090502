import React from 'react'
import styled from 'styled-components'
import { darken } from 'polished'
import { useTranslation } from 'react-i18next'
import { NavLink, Link as HistoryLink } from 'react-router-dom'
import { Text } from 'rebass'
import { ArrowLeft } from 'react-feather'
import { RowBetween } from '../Row'
import QuestionHelper from '../QuestionHelper'

import { ButtonPrimary } from '../Button'
import { useActiveWeb3React } from '../../hooks'

import { isMobile } from 'react-device-detect'
import { YellowCard } from '../Card'
import { ChainId } from 'zdfnswap-sdk'
const Tabs = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  border-radius: 3rem;
  justify-content: space-evenly;
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  justify-content: center;
  height: 3rem;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text3};
  font-size: 20px;

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 500;
    color: ${({ theme }) => theme.text1};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }
`

const StyledSubNavLink = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  min-width: 80px;
  align-items: center;
  justify-content: center;
  height: 2rem;
  border-radius: 6px;
  outline: none;
  border: 1px solid ${({ theme }) => theme.text3};
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text3};
  font-size: 14px;
  margin: 0 4px;

  &.active {
    border-radius: 6px;
    font-weight: 500;
    color: #ff7300;
    border-color: #ff7300;
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }
`

const ActiveText = styled.div`
  font-weight: 500;
  font-size: 20px;
`

const StyledArrowLeft = styled(ArrowLeft)`
  color: ${({ theme }) => theme.text1};
`

const TestnetWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-contents: center;
  pointer-events: auto;
  padding-bottom: 10px;
`

const NetworkCard = styled(YellowCard)`
  width: fit-content;
  margin-right: 10px;
  border-radius: 12px;
  padding: 8px 12px;
`

const NETWORK_LABELS: { [chainId in ChainId]: string | null } = {
  [ChainId.TESTNET]: 'Scroll Sepolia Testnet',
  [ChainId.SCROLL_ALPHA]: 'Scroll Alpha',
  [ChainId.MAINNET]: null,
  [ChainId.GÖRLI]: null,
  [ChainId.RINKEBY]: null,
  [ChainId.ROPSTEN]: null,
  [ChainId.KOVAN]: null
}

export function SwapPoolTabs({ active }: { active: 'swap' | 'pool' | 'analytics' }) {
  const { chainId } = useActiveWeb3React()
  return (
    <TestnetWrapper>
      {!isMobile && chainId && NETWORK_LABELS[chainId] && <NetworkCard>{NETWORK_LABELS[chainId]}</NetworkCard>}
    </TestnetWrapper>
  )
}

export function PoolSubTabs(props: { active: 'Pools' | 'My pools'; onChangeTab: Function }) {
  return (
    <RowBetween style={{ alignItems: 'center', marginBottom: '20px' }}>
      <Tabs style={{ justifyContent: 'center' }}>
        <StyledSubNavLink
          className={`${props.active === 'Pools' ? 'active' : ''}`}
          onClick={() => props.onChangeTab('Pools')}
        >
          Pools
        </StyledSubNavLink>
        <StyledSubNavLink
          className={`${props.active === 'My pools' ? 'active' : ''}`}
          onClick={() => props.onChangeTab('My pools')}
        >
          My Pools
        </StyledSubNavLink>
      </Tabs>
      <ButtonPrimary
        id="join-pool-button"
        as={HistoryLink}
        style={{ padding: '6px 15px', width: '120px' }}
        to="/add/ETH"
      >
        <Text fontWeight={500} fontSize={14}>
          Add Liquidity
        </Text>
      </ButtonPrimary>
    </RowBetween>
  )
}

export function FindPoolTabs() {
  return (
    <Tabs>
      <RowBetween style={{ padding: '1rem' }}>
        <HistoryLink to="/pool">
          <StyledArrowLeft />
        </HistoryLink>
        <ActiveText>Import Pool</ActiveText>
        <QuestionHelper text={"Use this tool to find pairs that don't automatically appear in the interface."} />
      </RowBetween>
    </Tabs>
  )
}

export function AddRemoveTabs({ adding }: { adding: boolean }) {
  return (
    <Tabs>
      <RowBetween style={{ padding: '1rem' }}>
        <HistoryLink to="/pool">
          <StyledArrowLeft />
        </HistoryLink>
        <ActiveText>{adding ? 'Add' : 'Remove'} Liquidity</ActiveText>
        <QuestionHelper
          text={
            adding
              ? 'When you add liquidity, you are given pool tokens representing your position. These tokens automatically earn fees proportional to your share of the pool, and can be redeemed at any time.'
              : 'Removing pool tokens converts your position back into underlying tokens at the current rate, proportional to your share of the pool. Accrued fees are included in the amounts you receive.'
          }
        />
      </RowBetween>
    </Tabs>
  )
}
