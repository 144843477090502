import styled from 'styled-components'
import React from 'react'
import Twitter from '../../assets/images/twitter.png'
import Discord from '../../assets/images/discord.png'
const padWidth = '1024'

const FootertipsDiv = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  font-size: 16px;
  padding: 0.5rem;
  width: 100%;
  background: #ff6a00;
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.text1};
  text-decoration: none;
`

const SocialMedia = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 24px;
  cursor: 'pointer';
`
const FooterContainersDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export default function Footer() {
  return (
    <FooterContainersDiv>
      <SocialMedia>
        <a target="_blank" className="social-icon" href="https://twitter.com/Hippo_Swap">
          <img src="/images/x.svg" className="chakra-image css-0" />
        </a>
        <a target="_blank" className="social-icon" href="https://t.me/hipposwap_scroll">
          <img src="/images/telegram.svg" className="chakra-image css-0" />
        </a>
        <a target="_blank" className="social-icon" href="https://discord.gg/GmhfBmhfJ8">
          <img src="/images/discord.svg" className="chakra-image css-0" />
        </a>
      </SocialMedia>
      <FootertipsDiv>© Hippo Swap. All Rights Reserved</FootertipsDiv>
    </FooterContainersDiv>
  )
}
